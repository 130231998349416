<template>
  <div class="cl-card">
    <img
      :src="imgPath"
      class="cc__courselist_img"
      width="65"
      height="65"
      @error="handleImgError"
    />
    <p>
      {{ title }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    imagePath: {
      type: String,
      required: true
    },
    defaultImage: {
      type: String,
      default:
        "https://files.lxp.academy.who.int/didactica/assets/images/course-card/course-illustration.svg"
    }
  },
  data() {
    return {
      imgPath: ""
    };
  },
  watch: {
    imagePath(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.imgPath = newVal;
      }
    }
  },
  mounted() {
    this.imgPath = this.imagePath;
  },
  methods: {
    handleImgError() {
      this.imgPath = this.defaultImage;
    }
  }
};
</script>
<style lang="scss" scoped>
.cl-card {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid $brand-neutral-200;
  cursor: pointer;
  .cc__courselist_img {
    border-radius: 8px;
    object-fit: cover;
  }
  p {
    margin: 0;
    padding-left: 8px;
    @include label-large;
    text-align: left;
  }
}
[dir="rtl"] {
  .cl-card {
    p {
      padding: 0 8px 0 0;
    }
  }
}
</style>
